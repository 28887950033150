import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import Modal from '@oberoninternal/travelbase-ds/components/layout/Modal';
import ModalHeader from '@oberoninternal/travelbase-ds/components/layout/ModalHeader';
import { useFormikContext } from 'formik';
import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useTenantContext } from '../context/TenantContext';
import { SearchValues } from '../entities/SearchValues';
import { UnitFilterPropertyCategory } from '../generated/graphql';
import { not } from '../utils/fp';
import CheckboxFilter from './CheckboxFilter';
import Formed from './Formed';
import RadioFilter from './RadioFilter';
import Stack from './Stack';

interface Props {
    open: boolean;
    onClose: () => void;
    allProperties?: UnitFilterPropertyCategory[];
}

const isPlaceFilter = (val: string) => val.startsWith('_place');

const SearchPlacesModal: FC<React.PropsWithChildren<Props>> = ({ allProperties, open, onClose }) => {
    const {
        setFieldValue,
        values: { orFilters, andFilters },
    } = useFormikContext<SearchValues>();

    const placesFilterCategory = useMemo(
        () => allProperties?.find(property => isPlaceFilter(property.handle)),
        [allProperties]
    );

    const {
        brandConfig: { placeFilter },
    } = useTenantContext();

    return (
        <Modal open={open} onClose={onClose} variant="regular">
            <ModalHeader>
                <FormattedMessage defaultMessage="Plaatsen toevoegen" />
            </ModalHeader>

            <Formed
                skipPrompt
                initialValues={{ places: orFilters.filter(isPlaceFilter) }}
                handleSubmit={values => {
                    if (placeFilter?.type === 'AND') {
                        setFieldValue('andFilters', [...andFilters.filter(not(isPlaceFilter)), ...values.places]);
                    } else {
                        setFieldValue('orFilters', [...orFilters.filter(not(isPlaceFilter)), ...values.places]);
                    }
                }}
            >
                {({ submitForm }) => (
                    <Stack my={3} spacing={4}>
                        {placesFilterCategory?.filterProperties.map(place =>
                            placeFilter?.type === 'AND' ? (
                                <RadioFilter
                                    key={place.handle}
                                    id={place.handle}
                                    label={place.name}
                                    categoryHandle={placesFilterCategory.handle}
                                    fieldName="places"
                                    fieldValue={`_place.${place.handle}`}
                                />
                            ) : (
                                <CheckboxFilter
                                    key={place.handle}
                                    id={place.handle}
                                    label={place.name}
                                    fieldName="places"
                                    fieldValue={`_place.${place.handle}`}
                                />
                            )
                        )}

                        <Button
                            onClick={() => {
                                onClose();
                                submitForm();
                            }}
                            type="button"
                            style={{ width: '100%' }}
                        >
                            <FormattedMessage defaultMessage="Plaatsen toevoegen" />
                        </Button>
                    </Stack>
                )}
            </Formed>
        </Modal>
    );
};

export default SearchPlacesModal;
