import { useQueryParams } from 'use-query-params';
import searchParams, { SearchParams } from '../constants/searchParams';
import SearchTrips from './pages/SearchTrips';
import SearchRentalUnits from './pages/SearchRentalUnits';
import SearchResults, { BaseProps } from './SearchResults';
import React, { ComponentProps, ComponentType, FC } from 'react';
import { mergeParams } from '../utils/search';
import removeUndefinedEntries from '../utils/removeUndefinedEntries';
import { getSearchBoxValuesFromStorage } from '../utils/searchBoxValuesFromStorage';
import { useTenantContext } from '../context/TenantContext';

export interface SearchProps extends BaseProps, Omit<ComponentProps<typeof SearchResults>, 'loading'> {
    defaultAccommodationType?: string;
    ignoreStorage?: boolean;
}

const getSearchPageComponent = (mergedParams: SearchParams): ComponentType<React.PropsWithChildren<SearchProps>> => {
    switch (mergedParams.type) {
        case 'flexible-random':
        case 'flexible-weekends':
        case 'coming-month':
            return SearchTrips;
        default:
        case 'static':
            if ((mergedParams.arrivalDate && mergedParams.departureDate) || mergedParams.period) {
                return SearchTrips;
            }
            return SearchRentalUnits;
    }
};

const Search: FC<React.PropsWithChildren<SearchProps>> = props => {
    const [params] = useQueryParams(searchParams);
    const { brandConfig } = useTenantContext();

    const initialStorageVals = !props.ignoreStorage ? getSearchBoxValuesFromStorage() : undefined;
    const mergedParams = mergeParams(
        props.startParams as SearchParams,
        initialStorageVals,
        removeUndefinedEntries(params),
        props.bakedInParams as SearchParams,
        brandConfig.bakedInFilterProperty
    );

    const SearchPage = getSearchPageComponent(mergedParams);

    return <SearchPage {...props} />;
};

export default Search;
