import gql from 'graphql-tag';
import React, { FC, useMemo } from 'react';
import { BoundingBoxInput, SearchRentalUnitsOrderEnum, useSearchPageRentalUnitsQuery } from '../../generated/graphql';
import { convertBookingToParamsInput, withDefaultBookingValues } from '../../utils/trip';
import { convertToFilters, getAccommodationFilters, mergeParams } from '../../utils/search';
import getDefaultAccommodationType from '../../utils/getDefaultAccommodationType';
import { getSearchBoxValuesFromStorage } from '../../utils/searchBoxValuesFromStorage';
import { useQueryParams } from 'use-query-params';
import searchParams from '../../constants/searchParams';
import SearchResults from '../SearchResults';
import { useTenantContext } from '../../context/TenantContext';
import { SearchProps } from '../Search';
import removeUndefinedEntries from '../../utils/removeUndefinedEntries';

export const query = gql`
    query SearchPageRentalUnits(
        $babies: Int!
        $filters: [PropertyFilterInput!]
        $persons: Int!
        $pets: Int!
        $mapArea: BoundingBoxInput
        $offset: Int
        $order: SearchRentalUnitsOrderEnum
        $limit: Int
        $badgeHandle: String!
        $badge2Handle: String!
    ) {
        searchRentalUnits(
            babies: $babies
            filters: $filters
            persons: $persons
            pets: $pets
            mapArea: $mapArea
            limit: $limit
            offset: $offset
            order: $order
        ) {
            hits {
                ...SearchHitRentalUnit
            }
            propertyFilterCounts {
                ...PropertyFilterCounts
            }
            totalHits
        }
    }
`;

const SearchRentalUnits: FC<React.PropsWithChildren<SearchProps>> = ({
    startParams = {},
    bakedInParams = {},
    ignoreStorage,
    defaultAccommodationType,
    amountPerPage,
    ...rest
}) => {
    const initialStorageVals = !ignoreStorage ? getSearchBoxValuesFromStorage() : undefined;
    const [params] = useQueryParams(searchParams);
    const { brandConfig } = useTenantContext();
    const {
        accommodationType = defaultAccommodationType ?? getDefaultAccommodationType(brandConfig),
        order = brandConfig.unitSearchOrder.popularity,
        offset = 0,
        andFilters = [],
        orFilters = [],
        bbLeft,
        bbRight,
        bbTop,
        bbBottom,
        ...booking
    } = withDefaultBookingValues(
        // merge initial params and current params and overwrite it with bakedIn params
        mergeParams(
            startParams,
            initialStorageVals,
            removeUndefinedEntries(params),
            bakedInParams,
            brandConfig.bakedInFilterProperty
        )
    );

    const filters = useMemo(
        () =>
            convertToFilters(
                [andFilters, 'AND'],
                [getAccommodationFilters(accommodationType), 'OR'],
                [orFilters, 'OR']
            ),
        [accommodationType, andFilters, orFilters]
    );

    const paramsInput = convertBookingToParamsInput(booking, {
        filters,
    });

    const mapArea: BoundingBoxInput | null =
        bbBottom && bbTop && bbLeft && bbRight ? { bottom: bbBottom, top: bbTop, left: bbLeft, right: bbRight } : null;

    const { data, loading } = useSearchPageRentalUnitsQuery({
        ssr: false,
        variables: {
            mapArea,
            ...paramsInput,
            // some protection as an offset < 0 will let the BE explode 🧨
            offset: offset >= 0 ? offset : 0,
            limit: amountPerPage,
            order:
                Object.values(SearchRentalUnitsOrderEnum).find(value => value === order) ??
                brandConfig.unitSearchOrder.popularity,
            badgeHandle: brandConfig.badge?.handle ?? '',
            badge2Handle: brandConfig.badge2?.handle ?? '',
        },
    });

    const { hits, propertyFilterCounts, totalHits } = data?.searchRentalUnits ?? {};

    return (
        <SearchResults
            amountPerPage={amountPerPage}
            loading={loading}
            propertyFilterCounts={propertyFilterCounts}
            totalHits={totalHits}
            defaultAccommodationType={defaultAccommodationType}
            searchOrder={brandConfig.unitSearchOrder}
            ignoreStorage={ignoreStorage}
            lastSearchParams={paramsInput}
            startParams={startParams}
            bakedInParams={bakedInParams}
            data={
                hits?.map(hit => ({
                    trips: [],
                    rentalUnit: hit,
                })) ?? []
            }
            {...rest}
        />
    );
};

export default SearchRentalUnits;
