import { Box } from '@rebass/grid';
import ErrorMessage from '@oberoninternal/travelbase-ds/components/form/ErrorMessage';
import { FieldConfig, useField } from 'formik';
import React, { ComponentPropsWithoutRef, ComponentType, forwardRef, Ref } from 'react';
import ReactSlider, { ReactSliderProps } from 'react-slider';
import styled from 'styled-components';
import ThumbStripes from '../svg/ThumbStripes.svg';
import { getAssignmentColor } from '@oberoninternal/travelbase-ds/constants/theme';

const Thumb = forwardRef((props: ComponentPropsWithoutRef<'div'>, ref: Ref<HTMLDivElement>) => (
    <SliderThumb {...props} ref={ref}>
        <ThumbStripes />
    </SliderThumb>
));
const Track = forwardRef((props: ComponentPropsWithoutRef<'div'>, ref: Ref<HTMLDivElement>) => (
    <SliderTrack {...props} ref={ref} />
));

export interface SliderFieldProps extends SliderProps {
    /** Specify whether formik should be updated after or during dragging. Default is after. */
    onChangeMethod?: 'after' | 'during';
}

export const SliderField = ({
    onChangeMethod = 'after',
    ...props
}: SliderFieldProps & FieldConfig<[number, number]>) => {
    const [{ value }, meta, helpers] = useField<[number, number]>(props.name);

    const events = {
        [onChangeMethod === 'after' ? 'onAfterChange' : 'onChange']: helpers.setValue,
    };

    return <Slider value={value} error={meta.touched && meta.error ? meta.error : undefined} {...events} {...props} />;
};

export interface SliderProps extends ReactSliderProps<[number, number]> {
    error?: string;
}

const Slider = ({ error, className, ...rest }: SliderProps) => (
    <Container className={className}>
        <StyledSlider
            renderThumb={props => <Thumb {...props} />}
            renderTrack={props => <Track {...props} />}
            {...rest}
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
);

const Container = styled(Box)`
    width: 28rem;

    .track {
        height: 0.7rem;
        margin: auto 0;
    }

    .disabled .track {
        background: ${({ theme }) => theme.colors.neutral['20']};
    }
`;

const StyledSlider = styled(ReactSlider)`
    width: 100%;
    height: 2.7rem;
    display: flex;
` as ComponentType<React.PropsWithChildren<SliderProps>>;

const SliderTrack = styled.div`
    background: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.main)};
    border-radius: ${({ theme }) => theme.radius.textInput};
    top: 0;
    bottom: 0;
`;

const SliderThumb = styled.div`
    width: 2.7rem;
    height: 2.7rem;
    background: ${({ theme }) => theme.colors.neutral['0']};
    color: ${({ theme }) => theme.colors.neutral['30']};
    border: 1px solid currentColor;
    box-shadow: 0px 16px 24px 0px rgba(59, 118, 160, 0.03), 0px 24px 80px 0px rgba(59, 118, 160, 0.05);
    border-radius: 50%;
    cursor: grab;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
`;

export default Slider;
