import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import { getFontCss } from '@oberoninternal/travelbase-ds/constants/theme';
import { Box, Flex } from '@rebass/grid';
import { useField } from 'formik';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import searchOrderMessages from '../constants/searchOrderMessages';
import { SearchRentalUnitsOrderEnum, UnitSearchOrderEnum } from '../generated/graphql';

const SearchOrderSelectInput: FC<
    React.PropsWithChildren<{
        name?: string;
        searchOrder: Record<string, SearchRentalUnitsOrderEnum | UnitSearchOrderEnum>;
    }>
> = ({ name = 'order', searchOrder }) => {
    const { formatMessage } = useIntl();
    const [{ value }, , { setValue }] = useField<SearchRentalUnitsOrderEnum>(name);

    const onChangeHandler = (val: string) => {
        setValue(val as SearchRentalUnitsOrderEnum);
    };

    if (Object.values(searchOrder).length === 0) {
        return null;
    }

    return (
        <SorterContainer className="gt-l">
            <StyledBox className="gt-l" mr={3}>
                <span>
                    <FormattedMessage defaultMessage="Gesorteerd op" />:
                </span>
            </StyledBox>
            <SorterSelect>
                {Object.keys(searchOrder).map(key => (
                    <Box key={key} mr={3}>
                        <StyledButton
                            size="small"
                            onClick={() => onChangeHandler(searchOrder[key])}
                            variant={value === searchOrder[key] ? 'primary' : 'outline'}
                        >
                            {formatMessage(searchOrderMessages[key])}
                        </StyledButton>
                    </Box>
                ))}
            </SorterSelect>
        </SorterContainer>
    );
};

export default SearchOrderSelectInput;

const SorterContainer = styled(Flex)`
    padding-left: ${({ theme }) => theme.spacing['30_Small']};
    height: 3.2rem;
    align-items: baseline;
    flex-shrink: 0;

    @media only screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        flex: 1;
    }

    font-size: ${({ theme }) => getFontCss(theme.fontSizes.body.small)};

    > p {
        color: ${({ theme }) => theme.colors.neutral['50']};
    }
`;

const StyledBox = styled(Box)`
    > span {
        white-space: nowrap;
    }
`;

const StyledButton = styled(Button)`
    @media only screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        > span {
            white-space: nowrap;
        }
    }
`;

const SorterSelect = styled(Flex)``;
