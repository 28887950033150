import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FilterCategory } from '../entities/Filter';
import { PropertyFilterCountsFragment, UnitFilterPropertyCategory } from '../generated/graphql';
import CheckboxFilter from './CheckboxFilter';
import FilterList from './FilterList';
import PlacesFilter from './PlacesFilter';
import PriceFilter from './PriceFilter';
import Special from '@oberoninternal/travelbase-ds/svg/Special.svg';
import { Box, Flex } from '@rebass/grid';
import Divider from './Divider';
import styled from 'styled-components';
import FilterListCategory from './FilterListCategory';
import messages from '../constants/messages';

interface Props {
    allProperties?: UnitFilterPropertyCategory[];
    hidePriceFilter: boolean;
    maximalMaxPrice: number;
    minimalMinPrice: number;
    specialCategories: FilterCategory[];
    unitFilterCategories: FilterCategory[];
    propertyFilterCounts: PropertyFilterCountsFragment[];
}

const AccommodationSearchFilters = ({
    allProperties,
    hidePriceFilter,
    maximalMaxPrice,
    minimalMinPrice,
    specialCategories,
    unitFilterCategories,
    propertyFilterCounts,
}: Props) => {
    const flexCancellationHits = propertyFilterCounts.find(
        filter => filter.property === '_pricing.flex-cancellation'
    )?.hits;
    const brandCategory = allProperties?.find(category => category.handle === '_brand');
    const multipleBrands = (brandCategory?.filterProperties?.length ?? 0) > 1;
    const intl = useIntl();
    return (
        <>
            {!!specialCategories.length && specialCategories.some(special => !!special.filters?.length) && (
                <>
                    <Flex alignItems="center">
                        <Box mr={3}>
                            <Special />
                        </Box>
                        <Box>
                            <FilterCaption variant="micro">
                                <FormattedMessage defaultMessage="Deals" />
                            </FilterCaption>
                        </Box>
                    </Flex>
                    {specialCategories.map(category =>
                        category?.filters?.map(filter => (
                            <CheckboxFilter
                                key={filter.handle}
                                label={filter.label}
                                fieldValue={filter.handle}
                                fieldName={category.formikKey ?? category.handle}
                                id={filter.handle}
                                hits={filter.hits}
                            />
                        ))
                    )}
                    <Divider />
                </>
            )}
            {!hidePriceFilter && minimalMinPrice !== maximalMaxPrice && (
                <>
                    <PriceFilter minPrice={minimalMinPrice} maxPrice={maximalMaxPrice} />
                    {!specialCategories.length && <Divider />}
                </>
            )}
            {!!flexCancellationHits && (
                <>
                    <CheckboxFilter
                        label={intl.formatMessage(messages.flexCancellation)}
                        fieldValue="_pricing.flex-cancellation"
                        fieldName="orFilters"
                        id="_pricing.flex-cancellation"
                        hits={flexCancellationHits}
                    />
                    <Divider />
                </>
            )}

            <FilterList
                categories={unitFilterCategories}
                categoryComponents={{
                    'type-of-accommodation': ({ category }) => <FilterListCategory category={category} type="radio" />,
                    _brand: ({ category }) => {
                        if (!multipleBrands) {
                            return null;
                        }
                        return <FilterListCategory category={category} />;
                    },
                    _place: props => {
                        if (multipleBrands) {
                            return null;
                        }
                        return <PlacesFilter allProperties={allProperties} {...props} />;
                    },
                }}
            />
        </>
    );
};

const FilterCaption = styled(Body)`
    font-weight: bold;
`;
export default AccommodationSearchFilters;
