import { defineMessages } from 'react-intl';
import { SearchOrderKey } from './brandConfig';

const searchOrderMessages = defineMessages<SearchOrderKey>({
    popularity: {
        defaultMessage: 'Populariteit',
    },
    ratingDesc: {
        defaultMessage: 'Beoordeling hoog / laag',
    },
    priceAsc: {
        defaultMessage: 'Prijs laag / hoog',
    },
    priceDesc: {
        defaultMessage: 'Prijs hoog / laag',
    },
});

export default searchOrderMessages;
