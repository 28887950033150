import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import { RadioField } from '@oberoninternal/travelbase-ds/components/form/Radio';
import ModalHeader from '@oberoninternal/travelbase-ds/components/layout/ModalHeader';
import { Sesame } from '@oberoninternal/travelbase-ds/hooks/useSesame';
import { Flex } from '@rebass/grid';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Stack from './Stack';
import searchOrderMessages from '../constants/searchOrderMessages';
import Modal from '@oberoninternal/travelbase-ds/components/layout/Modal';
import { SearchRentalUnitsOrderEnum, UnitSearchOrderEnum } from '../generated/graphql';

interface Props extends Pick<Sesame, 'onClose' | 'open'> {
    searchOrder: Record<string, SearchRentalUnitsOrderEnum | UnitSearchOrderEnum>;
}

const SearchSortModal: FC<React.PropsWithChildren<Props>> = props => {
    const { submitForm } = useFormikContext();

    return (
        <Modal {...props}>
            <ModalHeader style={{ textTransform: 'capitalize' }}>
                <FormattedMessage defaultMessage="sorteren" />
            </ModalHeader>
            <Stack my={6} spacing={4}>
                {Object.entries(props.searchOrder).map(([key, value]) => (
                    <Flex key={key} alignItems="center">
                        <RadioField name="order" id={value} />
                        <label htmlFor={value}>
                            <FormattedMessage {...searchOrderMessages[key]} />
                        </label>
                    </Flex>
                ))}
            </Stack>
            <Button
                style={{ width: '100%' }}
                onClick={() => {
                    props.onClose();
                    setTimeout(() => {
                        /* As the modal is rendered through a portal, the form won't be automatically submitted */
                        submitForm();
                    });
                }}
            >
                <FormattedMessage defaultMessage="Sortering toepassen" />
            </Button>
        </Modal>
    );
};

export default SearchSortModal;
