import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { gql } from '@apollo/client';
import { useField } from 'formik';
import isEqual from 'lodash.isequal';
import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled from 'styled-components';
import Slider, { SliderProps } from './designsystem/Slider';

export const fragment = gql`
    fragment SpecialCount on UnitSearchResult {
        specialCounts {
            special {
                description
                id
                landingPageUrl
                name
            }
            hits
        }
    }

    fragment TripTypeCount on UnitSearchResult {
        tripTypeCounts {
            hits
            tripType
        }
    }
`;

export interface Props {
    minPrice?: number;
    maxPrice?: number;
}

const PriceFilter: FC<React.PropsWithChildren<Props>> = ({ minPrice = 0, maxPrice = 0 }) => {
    const sameMinAndMax = minPrice === maxPrice;

    if (sameMinAndMax) {
        return null;
    }
    return (
        <>
            <FilterCaption>
                <FormattedMessage defaultMessage="Totaalprijs" />
            </FilterCaption>
            <PriceFilterSlider minPrice={minPrice} maxPrice={maxPrice} />
        </>
    );
};

export default PriceFilter;

interface PriceFilterSliderProps {
    minPrice: number;
    maxPrice: number;
}

type OnChange = SliderProps['onChange'];

const PriceFilterSlider = ({ minPrice, maxPrice }: PriceFilterSliderProps) => {
    const [{ value }, meta, { setValue, setTouched }] = useField<[number, number]>('price');
    const [currentPriceRange, setPriceRange] = useState(value);
    const [isChanging, setIsChanging] = useState(false);

    useEffect(() => {
        if (!isChanging && !isEqual(value, currentPriceRange)) {
            setPriceRange(value);
        }
    }, [currentPriceRange, isChanging, value]);

    const onAfterChange = (val: [number, number]) => {
        setIsChanging(false);
        setValue(val);
        if (!meta.touched) {
            setTouched(true);
        }
    };

    const [start, end] = currentPriceRange;

    return (
        <>
            <StyledSliderField
                minDistance={10}
                min={minPrice}
                max={maxPrice}
                defaultValue={meta.initialValue ?? value}
                value={currentPriceRange}
                onBeforeChange={() => setIsChanging(true)}
                onChange={setPriceRange as OnChange} // why tho
                onAfterChange={onAfterChange as OnChange}
            />
            <SliderBody>
                <FormattedMessage
                    defaultMessage="Van {minPrice} tot {maxPrice}"
                    values={{
                        minPrice: <FormattedNumber format="EUR_ROUNDED" value={Math.floor(start)} />,
                        maxPrice: <FormattedNumber format="EUR_ROUNDED" value={Math.ceil(end)} />,
                    }}
                />
            </SliderBody>
        </>
    );
};

const FilterCaption = styled(Body).attrs({ variant: 'micro' })`
    font-weight: bold;
    margin-bottom: ${({ theme }) => theme.spacing['40_Standard']};
`;

const StyledSliderField = styled(Slider)`
    @media screen and (max-width: ${({ theme }) => theme.mediaQueriesValues.s}px) {
        width: 100%;
    }
`;

const SliderBody = styled(Body)`
    font-weight: 600;
    font-size: 1.5rem;
    text-align: center;
    margin: ${({ theme }) => theme.spacing['40_Standard']} 0;
`;
