import ArrowRight from '@oberoninternal/travelbase-ds/components/figure/ArrowRight';
import { DeviceSize, useDeviceSize } from '@oberoninternal/travelbase-ds/context/devicesize';
import useSesame from '@oberoninternal/travelbase-ds/hooks/useSesame';
import { Flex } from '@rebass/grid';
import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { useTenantContext } from '../context/TenantContext';
import Stack from './Stack';
import WaterlandPompebled from './svg/WaterlandPompebled.svg';
import UspBody from './UspBody';
import UspsModal from './UspsModal';

const noIntervalDevices: DeviceSize[] = ['laptop', 'desktop'];
const INTERVAL = 3000;

interface Props {
    className?: string;
}

const Usps: FC<React.PropsWithChildren<Props>> = ({ className }) => {
    const modal = useSesame();
    const deviceSize = useDeviceSize();
    const [visibleIndex, setVisibleIndex] = useState<number | null>(null);
    const { brandConfig } = useTenantContext();
    const usps = brandConfig.usps ?? [];

    // loop through usps in sequence and set visibleIndex to the index of the visible usp to get that slideshow effect
    useEffect(() => {
        if (deviceSize && noIntervalDevices.includes(deviceSize)) {
            return undefined;
        }

        const interval = setInterval(() => {
            setVisibleIndex(index => {
                if (index === null) {
                    return 0;
                }
                if (index === usps.length - 1) {
                    return null;
                }
                return index + 1;
            });
        }, INTERVAL);

        return () => clearInterval(interval);
    }, [deviceSize, usps.length]);
    if (!usps.length) return null;

    return (
        <Container className={className}>
            <Flex
                onClick={modal.onOpen}
                as="button"
                justifyContent="center"
                className="lt-l"
                style={{ position: 'relative', height: '3rem', overflow: 'hidden' }}
                p={0}
                width={1}
            >
                <FadeInOut visible={visibleIndex === null}>
                    <Stack variant="inline" justifyContent="center" alignItems="center" spacing={3}>
                        <WaterlandPompebled />
                        <UspBody variant="large">
                            <FormattedMessage
                                values={{ count: usps.length }}
                                defaultMessage="{count} redenen om bij ons te boeken!"
                            />
                        </UspBody>
                        <ArrowRight />
                    </Stack>
                </FadeInOut>
                {usps.map(({ title }, index) => (
                    <FadeInOut key={index} visible={visibleIndex === index}>
                        <UspBody variant="large">{title}</UspBody>
                    </FadeInOut>
                ))}
            </Flex>
            <List className="gt-l" onClick={modal.onOpen}>
                {usps.map(({ title }, index) => (
                    <ClickableUsp
                        onClick={() => {
                            setVisibleIndex(index);
                            modal.onOpen();
                        }}
                        key={index}
                    >
                        <UspBody variant="large">{title}</UspBody>
                    </ClickableUsp>
                ))}
            </List>
            <UspsModal onClose={modal.onClose} open={modal.open} visibleIndex={visibleIndex ?? 0} />
        </Container>
    );
};

export default Usps;

const FadeInOut: FC<React.PropsWithChildren<{ visible: boolean }>> = ({ visible, children }) => {
    const styles = useSpring({ transform: visible ? 'translateY(0%)' : 'translateY(150%)' });

    return <StyledDiv style={styles}>{children}</StyledDiv>;
};

const StyledDiv = styled(animated.div)`
    position: absolute;
    color: ${({ theme }) => theme.colors.secondary[50]};
`;

const Container = styled.div`
    width: 100%;
    background: ${({ theme }) => theme.colors.secondary[0]};
    padding: ${({ theme }) => theme.spacing['40_Standard']} 0;
`;

const List = styled.div`
    display: flex;
    padding: 0;
    gap: ${({ theme }) => theme.spacing['60_Large']};
    justify-content: center;
    width: 100%;
`;

const ClickableUsp = styled.button`
    display: flex;
    padding: 0;
    gap: ${({ theme }) => theme.spacing['30_Small']};
    color: ${({ theme }) => theme.colors.secondary[50]};
    align-items: center;
`;
