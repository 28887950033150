import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { useDeviceSize } from '@oberoninternal/travelbase-ds/context/devicesize';
import useSesame from '@oberoninternal/travelbase-ds/hooks/useSesame';
import { Box, Flex } from '@rebass/grid';
import gql from 'graphql-tag';
import { FormikHelpers } from 'formik';
import isEqual from 'lodash.isequal';
import { useRouter } from 'next/router';
import React, { ComponentType, FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import searchParams, { searchFilterParams, SearchParams } from '../constants/searchParams';
import tripTypeLabel from '../constants/tripTypeTexts';
import { useTenantContext } from '../context/TenantContext';
import { Filter, FilterCategory } from '../entities/Filter';
import { SearchValues } from '../entities/SearchValues';
import {
    PropertyFilterCountsFragment,
    SearchHitRentalUnitFragment,
    SearchHitTripFragment,
    SearchRentalUnitsOrderEnum,
    SpecialCount,
    SpecialCountFragment,
    TripTypeCount,
    TripTypeEnum,
    UnitSearchOrderEnum,
    UnitSearchParamsInput,
    useUnitFilterPropertiesQuery,
} from '../generated/graphql';
import useAnalyticsContentCategory from '../hooks/analytics/useAnalyticsContentCategory';
import composeFilterCategory from '../hooks/search/useComposeFilterCategory';
import useFiltersActive from '../hooks/useFiltersActive';
import usePrevious from '../hooks/usePrevious';
import useQueryParams from '../hooks/useQueryParams';
import getDefaultAccommodationType from '../utils/getDefaultAccommodationType';
import { mergeParams } from '../utils/search';
import { getSearchBoxValuesFromStorage, setSearchBoxValuesToStorage } from '../utils/searchBoxValuesFromStorage';
import { withDefaultBookingValues } from '../utils/trip';
import AccommodationSearchFilters from './AccommodationSearchFilters';
import ContentWrapper, { ContentWrapperVariant } from './ContentWrapper';
import Heading from './designsystem/Heading';
import FilterList from './FilterList';
import Formed from './Formed';
import Page from './Page';
import SearchBar from './SearchBar';
import SearchBottomBar from './SearchBottomBar';
import { SearchBarValues } from './SearchBox';
import SearchFiltersModal from './SearchFiltersModal';
import SearchFilterTags from './SearchFilterTags';
import { interactables } from './SearchForm';
import SearchSortModal from './SearchSortModal';
import ViewToggle from './designsystem/ViewToggle';
import SearchOrderSelectInput from './SearchOrderSelectInput';
import { debounce } from 'debounce';
import Usps from './Usps';
import pick from 'lodash.pick';
import { Booking, bookingCartKeys } from '../entities/Booking';
import { stringify } from 'query-string';
import { DecodedValueMap } from 'use-query-params';

export const fragment = gql`
    fragment UnitFilterPropertyCategory on UnitFilterPropertyCategory {
        name
        handle
        filterProperties {
            name
            handle
        }
    }
`;

const getSpecialId = (values: SearchValues) => {
    const entry = values.specials[0];
    if (!entry) {
        return undefined;
    }

    return entry;
};

const searchFilterKeys: Array<keyof SearchValues> = ['andFilters', 'orFilters', 'specials', 'price', 'tripType'];

export interface ResultsData {
    rentalUnit: SearchHitRentalUnitFragment;
    trips: SearchHitTripFragment[];
}

export interface ResultsProps {
    isLoading: boolean;
    data?: ResultsData[] | null;
    pageCount: number;
    currentPageIndex: number;
    unitParams: Record<string, unknown>;
    activeSpecial?: SpecialCountFragment['specialCounts'][0] | TripTypeEnum;
    lastSearchParams?: UnitSearchParamsInput;
    filtersActive: boolean;
    removeActiveFilters: () => void;
    searchType: Booking['type'];
}

export type AllowedSearchAction = 'filters' | 'map' | 'list' | 'sort';

export interface BaseProps {
    bakedInParams?: SearchParams;
    startParams?: SearchParams;
}
interface Props extends BaseProps {
    ResultsComponent: ComponentType<React.PropsWithChildren<ResultsProps>>;
    amountPerPage: number;
    contentWrapperVariant: ContentWrapperVariant;
    buttonTypes: AllowedSearchAction[];
    lastSearchParams?: UnitSearchParamsInput;

    searchOrder?: Record<string, SearchRentalUnitsOrderEnum | UnitSearchOrderEnum>;
    data?: ResultsData[] | null;
    ignoreStorage?: boolean;
    onMapClick?: () => void;
    onListClick?: () => void;
    defaultAccommodationType?: string;
    loading: boolean;
    totalHits?: number | null;
    minPrice?: number | null;
    maxPrice?: number | null;
    specialCounts?: Array<SpecialCount> | null;
    tripTypeCounts?: Array<TripTypeCount> | null;
    propertyFilterCounts?: Array<PropertyFilterCountsFragment> | null;
    order?: SearchRentalUnitsOrderEnum | UnitSearchOrderEnum | null;
}

const SearchResults: FC<React.PropsWithChildren<Props>> = ({
    ResultsComponent,
    amountPerPage,
    contentWrapperVariant,
    buttonTypes,
    bakedInParams = {},
    defaultAccommodationType,
    startParams = {},
    ignoreStorage = false,
    totalHits = 0,
    tripTypeCounts = [],
    propertyFilterCounts = [],
    specialCounts,
    loading,
    data,
    searchOrder = {},
    lastSearchParams,
    minPrice,
    maxPrice,
    ...rest
}) => {
    const [params, setParams] = useQueryParams(searchParams);
    const initialStorageVals = !ignoreStorage ? getSearchBoxValuesFromStorage() : undefined;
    const pageRef = useRef<HTMLDivElement>(null);
    const { brandConfig } = useTenantContext();
    const router = useRouter();
    const {
        offset = 0,
        accommodationType = defaultAccommodationType ?? getDefaultAccommodationType(brandConfig),
        order = rest.order ?? searchOrder.popularity ?? null,
        andFilters = [],
        orFilters = [],
        tripType,
        specialId,
        minPrice: filterMinPrice,
        maxPrice: filterMaxPrice,
        minimalMinPrice,
        maximalMaxPrice,
        bbLeft,
        bbRight,
        bbTop,
        bbBottom,
        ...booking
    } = withDefaultBookingValues(
        // merge initial params and current params and overwrite it with bakedIn params
        mergeParams(startParams, initialStorageVals, params, bakedInParams, brandConfig.bakedInFilterProperty),
        brandConfig
    );
    const filterModal = useSesame();
    const sortModal = useSesame();

    const searchBarValues: SearchBarValues = useMemo(
        () => ({ booking, accommodationType }),
        [accommodationType, booking]
    );
    const prevSearchBoxValues = usePrevious(searchBarValues);

    useEffect(() => {
        // update localstorage with new searchbar values if they've changed
        if (!isEqual(prevSearchBoxValues, searchBarValues)) {
            setSearchBoxValuesToStorage(searchBarValues);
        }
    }, [prevSearchBoxValues, searchBarValues]);

    useAnalyticsContentCategory('accommodations');

    // we check if there are filters active in order to show a "remove filters" button
    const [filtersActive, removeActiveFilters] = useFiltersActive(searchFilterParams);

    // TODO(jari): fix
    // useAnalyticsUnitViewList(data?.searchTrips.hits ?? []);

    // if the filter is bakedin, we don't want to show it in the ui
    const filterPropertyIsBakedIn = useCallback(
        (handle: string) => {
            const predicate = (filter: string) => filter.startsWith(handle);
            return !!bakedInParams.orFilters?.some(predicate) || !!bakedInParams.andFilters?.some(predicate);
        },
        [bakedInParams.andFilters, bakedInParams.orFilters]
    );

    const { data: unitFilterPropertiesData, loading: filtersLoading } = useUnitFilterPropertiesQuery({ ssr: false });
    const allProperties = useMemo(() => {
        if (brandConfig.placeFilter?.hasPriority) {
            return unitFilterPropertiesData?.unitFilterProperties
                .filter(property => !filterPropertyIsBakedIn(property.handle))
                .sort((a, b) => {
                    if (a.handle === '_place') {
                        return -1;
                    }
                    if (b.handle === '_place') {
                        return 1;
                    }
                    return 0;
                });
        }
        return unitFilterPropertiesData?.unitFilterProperties.filter(
            property => !filterPropertyIsBakedIn(property.handle)
        );
    }, [brandConfig.placeFilter?.hasPriority, filterPropertyIsBakedIn, unitFilterPropertiesData?.unitFilterProperties]);

    const pageCount = totalHits && Math.ceil(totalHits / amountPerPage);
    const currentPageIndex = Math.floor(offset / amountPerPage);
    const baseMinPrice = filterMinPrice ?? minPrice ?? 1;
    const baseMaxPrice = filterMaxPrice ?? maxPrice ?? 20000;

    const propertiesAvailable = !!propertyFilterCounts && propertyFilterCounts?.length > 0;
    const prevBooking = useRef<SearchValues['booking'] | null>(null);

    const hidePriceFilter = !propertiesAvailable || typeof minPrice !== 'number' || typeof maxPrice !== 'number';

    const getActiveSearchbar = useCallback(() => {
        if (brandConfig.searchBar?.active) {
            return brandConfig.searchBar.active;
        }

        return interactables.filter(interactable => {
            if (bakedInParams.accommodationType) {
                return interactable !== 'accommodationType';
            }
            return true;
        });
    }, [bakedInParams.accommodationType, brandConfig]);

    const handleSubmit = useCallback(
        (values: SearchValues, helpers: FormikHelpers<SearchValues>) => {
            if (!prevBooking.current) {
                prevBooking.current = values.booking;
            }

            const [start, end] = values.price;
            const singlePrice = start === end;
            const newParams: Partial<DecodedValueMap<typeof searchParams>> = {
                andFilters: values.andFilters,
                orFilters: values.orFilters,
                tripType: values.tripType,
                specialId: getSpecialId(values),
                accommodationType: values.accommodationType,
                offset: 0,
                order: values.order,
                minPrice: singlePrice || start === minimalMinPrice ? undefined : start,
                maxPrice: singlePrice || end === maximalMaxPrice ? undefined : end,
                ...values.booking,
            };

            // did booking not change?
            if (isEqual(prevBooking.current, values.booking) && (start !== minPrice || end !== maxPrice)) {
                if (!minimalMinPrice) {
                    newParams.minimalMinPrice = minPrice;
                }

                if (!maximalMaxPrice) {
                    newParams.maximalMaxPrice = maxPrice;
                }
            } else {
                // if booking changed, delete all price filters
                newParams.minimalMinPrice = undefined;
                newParams.maximalMaxPrice = undefined;
                newParams.minPrice = undefined;
                newParams.maxPrice = undefined;
            }

            // by changing the params, apollo will refetch the query automagically
            setParams(newParams, 'replaceIn');
            setTimeout(() => helpers.setSubmitting(false));
            prevBooking.current = values.booking;
        },
        [maxPrice, maximalMaxPrice, minPrice, minimalMinPrice, setParams]
    );

    const deviceSize = useDeviceSize();
    const shouldAutoSave = deviceSize !== 'mobile';

    const unitFilterCategories = useMemo(
        () =>
            (propertyFilterCounts &&
                allProperties?.filter(Boolean).reduce<FilterCategory[]>((acc, next) => {
                    if (filterPropertyIsBakedIn(next.handle)) {
                        return acc;
                    }

                    return [
                        ...acc,
                        composeFilterCategory({
                            categoryHandle: next.handle,
                            categoryLabel: next.name,
                            filterCounts: propertyFilterCounts,
                            filterCountKey: 'property',
                            filterProperties: next.filterProperties,
                            filterResolver: ({ handle, name }) => ({ handle: `${next.handle}.${handle}`, label: name }),
                            formikKey:
                                next.handle === '_place' && brandConfig.placeFilter?.type !== 'AND'
                                    ? next.handle.startsWith('_')
                                        ? 'orFilters'
                                        : 'andFilters'
                                    : 'andFilters',
                        }),
                    ];
                }, [])) ??
            [],
        [allProperties, brandConfig.placeFilter?.type, filterPropertyIsBakedIn, propertyFilterCounts]
    );
    const tripTypeCategory = useMemo(() => {
        const tripTypes = Object.entries(tripTypeLabel).map(([key, value]) => ({
            label: value,
            handle: key,
        }));
        return composeFilterCategory({
            categoryHandle: 'tripType',
            filterCounts: tripTypeCounts ?? [],
            filterProperties: tripTypes,
            filterCountKey: 'tripType',
        });
    }, [tripTypeCounts]);

    const specialsCategory = useMemo(
        (): FilterCategory => ({
            handle: 'specials',
            filters: specialCounts?.map(
                ({ special, hits }): Filter => ({ handle: special.id, label: special.name, hits })
            ),
        }),
        [specialCounts]
    );

    const getActiveSpecial = (selectedSpecial?: string) =>
        specialCounts?.find(specialCount => specialCount.special.id === selectedSpecial);

    const unitParams = useMemo(
        () => ({
            ...pick(booking, bookingCartKeys),
            accommodationType,
            searched: router.asPath.split('?')[1],
        }),
        [accommodationType, booking, router.asPath]
    );
    const handleAutoSave = useMemo(() => debounce(handleSubmit), [handleSubmit]);
    const {
        onMapClick = () => {
            router.push({
                pathname: `/search/map`,
                search: router.asPath.split('?')[1],
            });
        },
        onListClick = () => {
            // remove bounding box.
            const search = { ...router.query };
            delete search.bbLeft;
            delete search.bbTop;
            delete search.bbRight;
            delete search.bbBottom;

            router.push({
                pathname: `/search`,
                search: stringify(search),
            });
        },
    } = rest;
    const accommodationSearchFilters = useMemo(
        () =>
            propertiesAvailable && (
                <AccommodationSearchFilters
                    propertyFilterCounts={propertyFilterCounts ?? []}
                    allProperties={allProperties}
                    hidePriceFilter={hidePriceFilter}
                    maximalMaxPrice={maximalMaxPrice ?? maxPrice ?? 1}
                    minimalMinPrice={minimalMinPrice ?? minPrice ?? 20000}
                    specialCategories={[specialsCategory, tripTypeCategory]}
                    unitFilterCategories={unitFilterCategories}
                />
            ),
        [
            propertiesAvailable,
            propertyFilterCounts,
            allProperties,
            hidePriceFilter,
            maximalMaxPrice,
            maxPrice,
            minimalMinPrice,
            minPrice,
            specialsCategory,
            tripTypeCategory,
            unitFilterCategories,
        ]
    );

    const initialValues: SearchValues = {
        booking,
        accommodationType,
        offset,
        orFilters,
        andFilters,
        tripType: tripType && !bakedInParams.tripType ? tripType : [],
        order: (rest.order ?? order) as SearchRentalUnitsOrderEnum,
        specials: specialId ? [specialId] : [],
        price: [baseMinPrice, baseMaxPrice],
    };

    return (
        <>
            <Page ref={pageRef} id="search-page">
                {brandConfig.showUsps && <Usps className="search-usps" />}
                <ContentWrapper variant={contentWrapperVariant}>
                    <Formed<SearchValues>
                        skipPrompt
                        initialValues={{ ...initialValues }}
                        handleSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {() => (
                            <SearchBar
                                variant={contentWrapperVariant === 'searchList' ? 'searchpage' : 'searchMap'}
                                hideSearchIcon
                                active={getActiveSearchbar()}
                            />
                        )}
                    </Formed>

                    <Formed<SearchValues>
                        skipPrompt
                        handleAutoSave={
                            shouldAutoSave
                                ? (values, helpers) => {
                                      helpers.setSubmitting(true);

                                      handleAutoSave(values, helpers);
                                  }
                                : undefined
                        }
                        initialValues={{ ...initialValues }}
                        handleSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {({ isSubmitting, values }) => {
                            const selectedSpecial = getSpecialId(values);
                            const activeSpecial = getActiveSpecial(selectedSpecial);
                            const isLoading = loading || isSubmitting;
                            // we show a skeleton when filters are actually loading or when the search results are loading and there aren't any filter properties available;
                            const showFilterLoader = filtersLoading || (isLoading && !propertiesAvailable);

                            const accommodationHitsMessage = totalHits ? (
                                <FormattedMessage
                                    defaultMessage="{hits} {totalHits, plural, one {accommodatie} other {accommodaties}}"
                                    values={{ hits: <HitsCount>{totalHits}</HitsCount>, totalHits }}
                                />
                            ) : null;

                            return (
                                <Flex flexDirection={['column', null, null, 'row']}>
                                    {/* hide the whole bar on small devices when the map is active */}
                                    <LeftBar className={contentWrapperVariant === 'searchMap' ? 'gt-m' : undefined}>
                                        <Heading className="gt-m">
                                            {isLoading && (
                                                <Box>
                                                    <Skeleton height="1.5em" width={250} />
                                                </Box>
                                            )}
                                            {!isLoading && (
                                                <Title style={{ fontWeight: 400 }}>{accommodationHitsMessage}</Title>
                                            )}
                                        </Heading>
                                        <SearchFilterTags
                                            shouldAutoSave={shouldAutoSave}
                                            categories={[tripTypeCategory, specialsCategory, ...unitFilterCategories]}
                                        />
                                        <Box className="gt-m">
                                            {showFilterLoader && <FilterList loading />}
                                            {!showFilterLoader && accommodationSearchFilters}
                                        </Box>
                                    </LeftBar>
                                    {!isLoading && contentWrapperVariant !== 'searchMap' && (
                                        <Body
                                            className="lt-s"
                                            variant="large"
                                            style={{ fontWeight: 400, marginTop: '2.4rem' }}
                                        >
                                            {accommodationHitsMessage}
                                        </Body>
                                    )}

                                    {/* only displayed on mobile */}
                                    <SearchBottomBar
                                        allowedActions={buttonTypes}
                                        filtersActive={filtersActive}
                                        onMapClick={onMapClick}
                                        onListClick={onListClick}
                                        onFiltersClick={filterModal.onOpen}
                                        onSortClick={sortModal.onOpen}
                                    />
                                    <SearchFiltersModal
                                        dirtyCheckKeys={searchFilterKeys}
                                        filtersActive={filtersActive}
                                        removeActiveFilters={removeActiveFilters}
                                        onClose={filterModal.onClose}
                                        open={filterModal.open}
                                    >
                                        {accommodationSearchFilters}
                                    </SearchFiltersModal>

                                    <SearchSortModal
                                        searchOrder={searchOrder}
                                        onClose={sortModal.onClose}
                                        open={sortModal.open}
                                    />
                                    <ResultsContainer>
                                        {buttonTypes.length > 0 && (
                                            <RightItems className="gt-m">
                                                {buttonTypes.includes('sort') && (
                                                    <SearchOrderSelectInput searchOrder={searchOrder} />
                                                )}

                                                {(buttonTypes.includes('map') || buttonTypes.includes('list')) && (
                                                    <ViewToggle
                                                        allowMap={buttonTypes.includes('map')}
                                                        allowList={buttonTypes.includes('list')}
                                                        onMap={onMapClick}
                                                        onList={onListClick}
                                                    />
                                                )}
                                            </RightItems>
                                        )}
                                        <ResultsComponent
                                            data={data}
                                            isLoading={isLoading}
                                            lastSearchParams={lastSearchParams}
                                            currentPageIndex={currentPageIndex}
                                            pageCount={pageCount ?? 0}
                                            activeSpecial={
                                                (bakedInParams.tripType?.[0] as TripTypeEnum) ?? activeSpecial
                                            }
                                            unitParams={unitParams}
                                            filtersActive={filtersActive}
                                            removeActiveFilters={removeActiveFilters}
                                            searchType={booking.type}
                                        />
                                    </ResultsContainer>
                                </Flex>
                            );
                        }}
                    </Formed>
                </ContentWrapper>
            </Page>
        </>
    );
};

export default SearchResults;

const HitsCount = styled.span`
    color: inherit;
`;

const LeftBar = styled(Box)`
    flex-shrink: 0;
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        width: 29.6rem;
        padding-right: ${({ theme }) => theme.spacing['60_Large']};
    }
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
        width: 32.8rem;
    }
`;

const RightItems = styled.div`
    display: flex;
    margin-left: auto;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 2rem;
    > * + * {
        margin-left: 2rem;
    }
`;

const ResultsContainer = styled.div`
    flex: 1;
`;
