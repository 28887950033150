import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import useSesame from '@oberoninternal/travelbase-ds/hooks/useSesame';
import { Flex } from '@rebass/grid';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { SearchValues } from '../entities/SearchValues';
import { UnitFilterPropertyCategory } from '../generated/graphql';
import SearchPlacesModal from './SearchPlacesModal';
import PlusIcon from './svg/PlusIcon.svg';
import Divider from './Divider';
import { getAssignmentColor } from '@oberoninternal/travelbase-ds/constants/theme';
import { useTenantContext } from '../context/TenantContext';

interface Props {
    allProperties?: UnitFilterPropertyCategory[];
}

const PlacesFilter: FC<React.PropsWithChildren<Props>> = props => {
    const { open, onOpen, onClose } = useSesame(false);
    const { brandConfig } = useTenantContext();
    const {
        values: { orFilters, andFilters },
    } = useFormikContext<SearchValues>();

    const numberOfSelectedPlaces =
        brandConfig.placeFilter?.type !== 'AND'
            ? orFilters
                ? orFilters.filter(filter => filter.startsWith('_place')).length
                : 0
            : andFilters
            ? andFilters.filter(filter => filter.startsWith('_place')).length
            : 0;
    // Hide the filter if it is disabled in the brandConfig, should be explicitly set to false
    if (brandConfig.placeFilter?.show === false) {
        return null;
    }
    return (
        <>
            <Flex my={2} flexDirection="column">
                <Body variant="micro" style={{ fontWeight: 'bold', marginBottom: '0.8rem' }}>
                    <FormattedMessage defaultMessage="Plaats" />
                </Body>
                <PlacesFilterButton
                    size="tiny"
                    onClick={onOpen}
                    hasPlacesSelected={!!numberOfSelectedPlaces}
                    hideBorderOnFocus
                >
                    {numberOfSelectedPlaces > 0 ? (
                        <FormattedMessage
                            defaultMessage="Plaatsen ({places})"
                            values={{
                                places: numberOfSelectedPlaces,
                            }}
                        />
                    ) : (
                        <>
                            <PlusIcon width={16} height={16} />
                            <FormattedMessage defaultMessage="Plaats toevoegen" />
                        </>
                    )}
                </PlacesFilterButton>
            </Flex>
            <Divider />
            <SearchPlacesModal open={open} onClose={onClose} {...props} />
        </>
    );
};

export default PlacesFilter;

const PlacesFilterButton = styled(TextButton)<{ hasPlacesSelected: boolean }>`
    color: ${({ theme }) => theme.colors.neutral['70']};
    justify-content: flex-start;
    display: inline-block;
    max-width: ${({ hasPlacesSelected }) => (hasPlacesSelected ? '10rem' : 'none')};
    span {
        font-weight: 600;
        svg {
            margin: 0 1.2rem 0.2rem 0.4rem;
        }
        align-items: center;
    }
    &:hover {
        color: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.textAction)};
        span::after {
            border: none;
        }
    }

    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        padding: 1rem;
        border: 1px solid ${({ theme }) => theme.colors.neutral['20']};
        box-shadow: 0px 1.6rem 2.4rem 0 rgba(59, 118, 160, 0.03), 0 2.4rem 8rem 0 rgba(59, 118, 160, 0.05);
        border-radius: 0.8rem;
    }
    transition: color 0.15s ease-in-out;
`;
