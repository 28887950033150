import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { TripTypeEnum } from '../generated/graphql';

const tripTypeLabel: Record<Exclude<TripTypeEnum, 'REGULAR' | 'SPECIAL'>, ReactNode> = {
    EARLY_BOOKING: <FormattedMessage defaultMessage="Vroegboekkorting aanbiedingen" />,
    LAST_MINUTE: <FormattedMessage defaultMessage="Last-minute aanbiedingen" />,
};

export default tripTypeLabel;
