import gql from 'graphql-tag';
import React, { FC, useMemo } from 'react';
import { BoundingBoxInput, TripTypeEnum, UnitSearchOrderEnum, useSearchTripsQuery } from '../../generated/graphql';
import { convertBookingToParamsInput, getTripDuration, withDefaultBookingValues } from '../../utils/trip';
import { convertToFilters, getAccommodationFilters, mergeParams } from '../../utils/search';
import getDefaultAccommodationType from '../../utils/getDefaultAccommodationType';
import { getSearchBoxValuesFromStorage } from '../../utils/searchBoxValuesFromStorage';
import { useQueryParams } from 'use-query-params';
import searchParams from '../../constants/searchParams';
import SearchResults from '../SearchResults';
import { useTenantContext } from '../../context/TenantContext';
import { SearchProps } from '../Search';
import { Booking } from '../../entities/Booking';
import parse from 'date-fns/parse';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import parseToDateString from '../../constants/parseToDateString';
import add from 'date-fns/add';
import isWeekend from 'date-fns/isWeekend';
import removeUndefinedEntries from '../../utils/removeUndefinedEntries';

export const query = gql`
    query SearchTrips(
        $babies: Int!
        $date: [String!]!
        $duration: [Int!]!
        $filters: [PropertyFilterInput!]
        $persons: Int!
        $pets: Int!
        $price: RangeFilterInput
        $specialId: [ID!]
        $tripType: [TripTypeEnum!]
        $mapArea: BoundingBoxInput
        $limit: Int
        $offset: Int
        $order: UnitSearchOrderEnum
        $badgeHandle: String!
        $badge2Handle: String!
    ) {
        searchTrips(
            babies: $babies
            date: $date
            duration: $duration
            filters: $filters
            persons: $persons
            pets: $pets
            price: $price
            specialId: $specialId
            tripType: $tripType
            mapArea: $mapArea
        ) {
            hits(limit: $limit, offset: $offset, order: $order) {
                ...SearchHitTrip
                rentalUnit {
                    ...SearchHitRentalUnit
                }
            }

            # TODO jordi rightfully says: niet het veld stats (opnieuw) opvragen bij elke keer dat je naar de volgende pagina resultaten bladert.
            stats {
                totalHits
                propertyFilterCounts {
                    ...PropertyFilterCounts
                }
                minPrice
                maxPrice
                tripTypeCounts {
                    tripType
                    hits
                }
                specialCounts {
                    special {
                        description
                        id
                        landingPageUrl
                        name
                    }
                    hits
                }
            }
        }
    }

    fragment ListImage on ImageTransform {
        placeholder
        srcSet
        src
        ratio
    }

    query UnitFilterProperties {
        unitFilterProperties {
            name
            handle
            filterProperties {
                name
                handle
            }
        }
    }

    query UnitSearchPriceFilter($params: UnitSearchParamsInput!) {
        unitSearch(params: $params) {
            minPrice
            maxPrice
        }
    }

    fragment PropertyFilterCounts on PropertyFilterCount {
        hits
        property
    }
`;

const getFlexibleDates = (type: Booking['type'], month: string): string[] => {
    const parsedMonth = parse(month, 'MM-yyyy', new Date());
    const interval = {
        start: startOfMonth(parsedMonth),
        end: endOfMonth(parsedMonth),
    };
    let dates = eachDayOfInterval(interval);

    if (type === 'flexible-weekends') {
        dates = dates.filter(isWeekend);
    }

    return dates.map(parseToDateString);
};

const SearchTrips: FC<React.PropsWithChildren<SearchProps>> = ({
    startParams = {},
    bakedInParams = {},
    ignoreStorage,
    defaultAccommodationType,
    amountPerPage,
    ...rest
}) => {
    const initialStorageVals = !ignoreStorage ? getSearchBoxValuesFromStorage() : undefined;
    const [params] = useQueryParams(searchParams);
    const { brandConfig } = useTenantContext();
    const {
        offset = 0,
        accommodationType = defaultAccommodationType ?? getDefaultAccommodationType(brandConfig),
        order = brandConfig.tripsSearchOrder.popularity,
        andFilters = [],
        orFilters = [],
        tripType,
        specialId,
        minPrice,
        maxPrice,
        bbLeft,
        bbRight,
        bbTop,
        bbBottom,
        ...booking
    } = withDefaultBookingValues(
        // merge initial params and current params and overwrite it with bakedIn params
        mergeParams(
            startParams,
            initialStorageVals,
            removeUndefinedEntries(params),
            bakedInParams,
            brandConfig.bakedInFilterProperty
        )
    );

    const {
        amountBabies,
        amountPets,
        amountAdults,
        amountChildren,
        amountYouths,
        type,
        amountOfNights,
        flexibleMonth,
        arrivalDate,
        departureDate,
    } = booking;

    const filters = useMemo(
        () =>
            convertToFilters(
                [andFilters, 'AND'],
                [getAccommodationFilters(accommodationType), 'OR'],
                [orFilters, 'OR']
            ),
        [accommodationType, andFilters, orFilters]
    );

    const mapArea: BoundingBoxInput | null =
        bbBottom && bbTop && bbLeft && bbRight ? { bottom: bbBottom, top: bbTop, left: bbLeft, right: bbRight } : null;
    const isFlexible = type !== 'static' && amountOfNights && flexibleMonth;
    const isComingMonth = type === 'coming-month';

    const paramsInput = convertBookingToParamsInput(booking, {
        filters,
    });

    const getComingMonthsDate = (): string[] => {
        const interval = {
            start: new Date(),
            end: add(new Date(), { months: 1 }),
        };
        const dates = eachDayOfInterval(interval);

        return dates.map(parseToDateString);
    };

    const vars = {
        mapArea,
        filters,
        tripType: tripType as TripTypeEnum[],
        specialId,
        pets: amountPets,
        persons: amountAdults + amountChildren + amountYouths,
        babies: amountBabies,
        price: {
            min: minPrice,
            max: maxPrice,
        },
        duration: isComingMonth
            ? [1, 2, 3, 4, 5, 6, 7]
            : isFlexible
            ? amountOfNights
            : getTripDuration(arrivalDate, departureDate),
        date: isComingMonth ? getComingMonthsDate() : isFlexible ? getFlexibleDates(type, flexibleMonth) : arrivalDate,

        // some protection as an offset < 0 will let the BE explode 🧨
        offset: offset >= 0 ? offset : 0,
        limit: amountPerPage,
        order:
            Object.values(UnitSearchOrderEnum).find(value => value === order) ??
            brandConfig?.defaultTripSearchOrderByRentalUnitType?.[accommodationType] ??
            brandConfig.tripsSearchOrder.popularity,
        badgeHandle: brandConfig.badge?.handle ?? '',
        badge2Handle: brandConfig.badge2?.handle ?? '',
    };

    const { data, loading } = useSearchTripsQuery({
        ssr: false,
        variables: vars,
    });

    const stats = data?.searchTrips?.stats;
    const hits = data?.searchTrips?.hits;

    return (
        <SearchResults
            amountPerPage={amountPerPage}
            loading={loading}
            tripTypeCounts={stats?.tripTypeCounts}
            propertyFilterCounts={stats?.propertyFilterCounts}
            specialCounts={stats?.specialCounts}
            totalHits={stats?.totalHits}
            defaultAccommodationType={defaultAccommodationType}
            searchOrder={brandConfig.tripsSearchOrder}
            minPrice={stats?.minPrice}
            maxPrice={stats?.maxPrice}
            lastSearchParams={paramsInput}
            startParams={startParams}
            bakedInParams={bakedInParams}
            ignoreStorage={ignoreStorage}
            order={vars.order}
            data={
                hits?.map(hit => ({
                    trips: [hit],
                    rentalUnit: hit.rentalUnit,
                })) ?? []
            }
            {...rest}
        />
    );
};

export default SearchTrips;
